import React from 'react'
import Layout from '../layouts/MainLayout'
import SEO from '@/components/seo'
import error from '../images/error.svg'
import classes from '../components/css/404.module.css'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <div className={classes.errorPage}>
      <div className={classes.container}>
        <div className={classes.errorDescription}>
          <div className={classes.title}>Упс!</div>
          <div className={classes.subtitle}>
            <div>Кажется, такой страницы больше нет. </div>
            <div>Приносим свои извинения</div>
          </div>
        </div>
        <div className={classes.errorImage}>
          <img src={error} />
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
